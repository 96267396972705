import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyDExMFl-wD6BvVLYtzuMTTUVhYpz736dnI',
		authDomain: 'alawda-school-app.firebaseapp.com',
		projectId: 'alawda-school-app',
		storageBucket: 'alawda-school-app.appspot.com',
		messagingSenderId: '917908848819',
		appId: '1:917908848819:web:8c805e33a981ab7b74b0c6'	  
	},
	publicKey: 'BK_Xy421vXDNDtkMDxdvJw2iejv1BtQTsHodwj2MU_lDIlzYlNNjYX_JwEeC7qKj6Xs4Vmgz40KZyEGLHKVwS3s',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
