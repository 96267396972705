module.exports = {
	colors: {
		main: '#e73d4a',
		sec: '#3a3f4f',
		alt: '#e73d4a',
	},

	showFunds: true,

	showBuses: false,

	showHomeworks: true,

	about: {
		mobiles: [],
		phones: ['0212220752', '0212246760', '0212246760'],
		address: 'الجميلية - خلف شارع اسكندرون',
		data: [36.205419, 37.142509],
		email: null,
		facebook: 'https://www.facebook.com/awda.high.school',
		privacy: 'https://al-awdah.beetronix.com/privacy-policy/',
		details: [
			{
				title: 'لمحة عن تاريخ ثانوية العودة الخاصة',
				content: [
					'تأسّست مدرسة العودة الخاصّة في مدينة حلب عام \\ 1964م\\ بمساعي المربّي الفاضل المرحوم محمّد صالح ترمانيني الذي كان يمتلك باعاً طويلاً في مجال التعليم الخاصّ.\n',
					'فهو الذي أسهم في تأسيس أوّل مدرسة اعداديّة في مسقط رأسه (قرية ترمانين) وذلك في عام \\1953م\\.\n',
					'كانت مدرسة العودة في بدايتها مدرسةً ابتدائيّة حتّى جاء عام\\1980م\\، حيث تم تعديل ترخيصها لتصبح مدرسة للمرحلتين الإعداديّة (التعليم الأساسي) والثانويّة.\n',
					'ومنذ ذلك التاريخ سرعت ثانويّة العودة بتخريج الطلاّب الذين التحقوا بالجامعات السوريّة كأطباء ومهندسين ومدرّسين في جميع المجالات وصلت أعدادهم إلى الآلاف من الطلاّب والطالبات الذين تركوا بصمةً في حياة مدينتنا حلب السياسيّة والثقافيّة والعلميّة والاجتماعيّة والاقتصاديّة في وطننا الحبيب سورية.\n',
				],
			},
		],
	},

	pwa: {
		name: 'ثانوية العودة الخاصة',
		shortName: 'ثانوية العودة الخاصة',
		description: 'تطبيق ثانوية العودة الخاصة',
	},
};
